import React, { useRef, useState } from "react";
import FeaturedTable from "../../widgets/tables/FeaturedTable";
import Toolbar from "../../widgets/tools/Toolbar";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faPenToSquare,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import SubTypeForm from "./SubTypeForm";

// constants
const genTblRow = (row, i) => (
  <tr key={i}>
    <th>{i + 1}</th>
    <td>{row.type_name}</td>
    <td>{row.name}</td>

    <td>
      <button>
        <FontAwesomeIcon icon={faEye} color="gray" />
      </button>
      <button>
        <FontAwesomeIcon icon={faPenToSquare} color="green" />
      </button>
      <button>
        <FontAwesomeIcon icon={faTrashCan} color="red" />
      </button>
    </td>
  </tr>
);
const genTblHead = () => (
  <tr>
    <th>S. No</th>
    <th>Type</th>
    <th>Name</th>

    <th>Action</th>
  </tr>
);
const listUrl = "/admin/work-sub-types";

const WorkSubType = () => {
  const tableRef = useRef(null);
  const [formInfo, setFormInfo] = useState({ show: false, id: null });

  const toolActions = {
    refresh: () => {
      tableRef.current.loadTableData();
    },
    add: () => {
      setFormInfo({ show: true, id: null });
    },
  };
  return (
    <main>
      <div className="card">
        <Toolbar actions={toolActions} title="Work Sub Type List" />
        <FeaturedTable
          ref={tableRef}
          genTblRow={genTblRow}
          genTblHead={genTblHead}
          listUrl={listUrl}
        />
      </div>
      {formInfo.show && (
        <SubTypeForm
          close={() => setFormInfo({ show: false, id: null })}
          id={formInfo.id}
          refresh={toolActions.refresh}
        />
      )}
    </main>
  );
};

export default WorkSubType;
