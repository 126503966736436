import React, { useState } from "react";

// components
import TopNav from "./TopNav";
import SideNav from "./SideNav";

const Navigation = () => {
  const [sideNavShow, setSideNavShow] = useState(false);
  const toggleSidNav = () => setSideNavShow(!sideNavShow);

  return (
    <>
      <TopNav toggleSidNav={toggleSidNav} />
      <SideNav show={sideNavShow} toggleSidNav={toggleSidNav} />
    </>
  );
};

export default Navigation;
