import React from "react";
import { NavLink } from "react-router-dom";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faDashboard,
  // faFile,
  // faChartSimple,
  // faCashRegister,
  // faUniversalAccess,
  // faFileInvoice,
  // faRightToBracket,
  faUser,
  faUserTie,
  faClipboardUser,
  faGraduationCap,
  faUserInjured,
  faBriefcase,
  faBuildingCircleCheck,
  faIcons,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const SideNav = (props) => {
  return (
    <nav className={`side-nav ${props.show && "show"}`}>
      <div>
        <h1>Rozi Roti</h1>
        <button onClick={props.toggleSidNav}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div>
        <h3>MENU</h3>
        <ul>
          <li>
            <NavLink to="/unknown">
              <FontAwesomeIcon icon={faUser} />
              User
            </NavLink>
            <ul>
              <li>
                <NavLink to="/users/admins">
                  <FontAwesomeIcon icon={faUserTie} />
                  Admin
                </NavLink>
              </li>
              <li>
                <NavLink to="/users/staffs">
                  <FontAwesomeIcon icon={faClipboardUser} />
                  Staff
                </NavLink>
              </li>
              <li>
                <NavLink to="/users/employees">
                  <FontAwesomeIcon icon={faGraduationCap} />
                  Employee
                </NavLink>
              </li>
              <li>
                <NavLink to="/users/employers">
                  <FontAwesomeIcon icon={faUserInjured} />
                  Employer
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faFileInvoice} />
                  CRM
                </NavLink>
              </li> */}
            </ul>
          </li>
          <li>
            <NavLink to="/unknown">
              <FontAwesomeIcon icon={faIcons} />
              Category
            </NavLink>
            <ul>
              <li>
                <NavLink to="/work/types">
                  <FontAwesomeIcon icon={faBriefcase} />
                  Work Type
                </NavLink>
              </li>
              <li>
                <NavLink to="/work/sub-types">
                  <FontAwesomeIcon icon={faBuildingCircleCheck} />
                  Work sub-type
                </NavLink>
              </li>

              {/* <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faDashboard} />
                  Minimal
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faDashboard} />
                  CRM
                </NavLink>
              </li> */}
            </ul>
          </li>
        </ul>
      </div>
      {/* <div>
        <h3>UI COMPONENTS</h3>
        <ul>
          <li>
            <NavLink to="/unknown">
              <FontAwesomeIcon icon={faDashboard} /> Dashboard
            </NavLink>
            <ul>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faChartSimple} />
                  Analytics
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faCashRegister} />
                  Commerce
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faUniversalAccess} />
                  Sales
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faUniversalAccess} />
                  Minimal
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faFileInvoice} />
                  CRM
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/unknown">
              <FontAwesomeIcon icon={faFile} />
              Pages
            </NavLink>
            <ul>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faRightToBracket} />
                  Login
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faUser} />
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faUser} />
                  Account
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faDashboard} />
                  Minimal
                </NavLink>
              </li>
              <li>
                <NavLink to="/unknown">
                  <FontAwesomeIcon icon={faDashboard} />
                  CRM
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div> */}
    </nav>
  );
};

export default SideNav;
