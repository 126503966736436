import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotate,
  faPlus,
  faFloppyDisk,
  faDownload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const Toolbar = ({ actions, title }) => {
  return (
    <div className="toolbar">
      <h2>{title}</h2>
      <div>
        <button onClick={actions.refresh} disabled={!actions.refresh}>
          <FontAwesomeIcon icon={faRotate} />
        </button>
        <button onClick={actions.add} disabled={!actions.add}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <button onClick={actions.save} disabled={!actions.save}>
          <FontAwesomeIcon icon={faFloppyDisk} />
        </button>
        <button disabled={!actions.download}>
          <FontAwesomeIcon icon={faDownload} />
        </button>
        <button onClick={actions.close} disabled={!actions.close}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
