import React, { Component } from "react";
import { getFromServer } from "../../../globals/requests";
import { showStatusSoundToast } from "../../../globals/messages";

import TableFilter from "./TableFilter";
import TablePagination from "./TablePagination";

export class FeaturedTable extends Component {
  constructor(props) {
    super(props);

    // Initializing state
    this.state = {
      list: [],
    };
  }

  // function will call first time onload.
  componentDidMount() {
    this.loadTableData();
  }

  // methods
  async loadTableData() {
    // this.setState({ list: [] });
    const result = await getFromServer(
      `${this.props.listUrl}?${this.props.extraQueries}`
    );
    if (result.status) {
      this.setState({ list: result.data.list });
      console.log(result.data);
    } else showStatusSoundToast(result.status, result.msg);
  }

  render() {
    return (
      <>
        <TableFilter />
        <div className="tbl-box">
          <table>
            <thead>{this.props.genTblHead()}</thead>
            <tbody>{this.state.list.map(this.props.genTblRow)}</tbody>
          </table>
        </div>
        <TablePagination />
      </>
    );
  }
}

export default FeaturedTable;
