// css files
// import './App.css';
import "./assets/styles/index.css";
import "./assets/styles/responsive.css";

// notifications
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// components
import MainApp from "./components/MainApp";

function App() {
  return (
    <>
      <MainApp />
      <ToastContainer position="top-right" theme="colored" />
    </>
  );
}

export default App;
