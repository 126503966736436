import React, { useRef, useState, useEffect } from "react";
import FormPopup from "../../widgets/popups/FormPopup";
import { getFromServer, postToServer } from "../../../globals/requests";
import { getFormInputsData } from "../../../globals/helpers";
import { showStatusSoundToast } from "../../../globals/messages";

const SubTypeForm = (props) => {
  const refs = {
    type: useRef(null),
    subtype: useRef(null),
  };
  const [loading, setLoading] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const toolActions = {
    refresh: () => setLoading(!loading),
    close: () => props.close(),
    save: async () => {
      const data = getFormInputsData(refs);
      console.log(data);
      const result = await postToServer("/admin/work-sub-type/create", data);
      if (result.status) {
        props.close();
        props.refresh();
      }
      showStatusSoundToast(result.status, result.detail);
    },
  };

  const GetList = async () => {
    const result = await getFromServer("/admin/work-types");
    if (result.status) {
      setTypeList(result.data.list);
    }
  };
  useEffect(() => {
    GetList();
  }, []);
  return (
    <FormPopup
      close={props.close}
      toolActions={toolActions}
      loading={loading}
      title="WorK Sub Type Form"
      size="sm"
    >
      <form className="form">
        <div className="c1">
          <p>
            <label htmlFor="type">Work Type</label>
            <select name="type" id="type" ref={refs.type} required>
              <option value="">Select Type</option>
              {typeList.map((row, i) => (
                <option key={i} value={row.id}>
                  {row.name}
                </option>
              ))}
            </select>
          </p>
          <p>
            <label htmlFor="subtype">Work SubType</label>
            <input type="text" ref={refs.subtype} required id="subtype"></input>
          </p>
        </div>
      </form>
    </FormPopup>
  );
};

export default SubTypeForm;
