import React, { useRef } from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faUser,
  faKey,
  faLock,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

import { getFormInputsData } from "../../../globals/helpers";
import { postToServer } from "../../../globals/requests";

import { showStatusSoundToast } from "../../../globals/messages";
import { useDispatch } from "react-redux";
import { login } from "../../../app/slices/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const refs = {
    username: useRef(null),
    password: useRef(null),
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    const reqData = getFormInputsData(refs);
    if (!reqData) return false;
    const result = await postToServer("/acc/login", reqData);
    if (result.status) dispatch(login(result.data));
    showStatusSoundToast(result.status, result.detail);
  };

  return (
    <div className="login-cont">
      <h1>
        <FontAwesomeIcon icon={faGraduationCap} /> Rozi Roti
      </h1>
      <div className="card">
        <h2>
          <FontAwesomeIcon icon={faLock} /> LOGIN
        </h2>
        <form className="i-form" onSubmit={submitLogin}>
          <p>
            <FontAwesomeIcon icon={faUser} color="green" />
            <input
              type="text"
              ref={refs.username}
              placeholder="Username"
              required
            />
          </p>
          <p>
            <FontAwesomeIcon icon={faKey} color="green" />
            <input
              type="password"
              ref={refs.password}
              placeholder="Password"
              required
            />
          </p>
          <div style={{ textAlign: "center" }}>
            <button className="btn">
              <FontAwesomeIcon icon={faRightToBracket} /> LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
