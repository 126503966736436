import React, { useRef, useState } from "react";
import FormPopup from "../../widgets/popups/FormPopup";
import { getFormInputsData } from "../../../globals/helpers";
import {
  showErrorSoundToast,
  showStatusSoundToast,
} from "../../../globals/messages";
import { postToServer } from "../../../globals/requests";

const UserForm = (props) => {
  const refs = {
    name: useRef(null),
    username: useRef(null),
    mobile: useRef(null),
    email: useRef(null),
    password: useRef(null),
    comf_pwd: useRef(null),
  };

  const [loading, setLoading] = useState(false);
  const toolActions = {
    refresh: () => setLoading(!loading),
    close: () => props.close(),
    save: async () => {
      const data = getFormInputsData(refs);
      if (!data) return;
      if (data.password !== data.comf_pwd) {
        showErrorSoundToast("Password and Conform Password are not Match");
        return;
      }
      data.role = props.role;
      const result = await postToServer("/admin/user/create", data);
      if (result.status) {
        props.close();
        props.refresh();
      }
      showStatusSoundToast(result.status, result.detail);
    },
  };

  return (
    <FormPopup
      close={props.close}
      toolActions={toolActions}
      loading={loading}
      title={props.title}
      size="lg"
    >
      <form className="form">
        <div>
          <p>
            <label htmlFor="name">Name</label>
            <input type="text" required ref={refs.name} id="name" />
          </p>
          <p>
            <label htmlFor="username">Username</label>
            <input type="text" required ref={refs.username} id="username" />
          </p>
        </div>
        <div>
          <p>
            <label htmlFor="email">Email</label>
            <input type="email" required ref={refs.email} id="email" />
          </p>
          <p>
            <label htmlFor="mobile">Mobile</label>
            <input type="number" required ref={refs.mobile} id="mobile" />
          </p>
        </div>
        <div>
          <p>
            <label htmlFor="password">Password</label>
            <input type="password" required ref={refs.password} id="password" />
          </p>
          <p>
            <label htmlFor="conf_pwd">Confirm Password</label>
            <input type="conf_pwd" required ref={refs.comf_pwd} id="conf_pwd" />
          </p>
        </div>
      </form>
    </FormPopup>
  );
};

export default UserForm;
