import { toast } from "react-toastify";
import { playErrorSound, playNotiSound } from "./sounds";

const showStatusSoundToast = (
  status = false,
  text = "Something went wrong."
) => {
  if (status) {
    toast.success(text);
    playNotiSound();
  } else {
    toast.error(text);
    playErrorSound();
  }
};

const showErrorSoundToast = (text = "") => {
  toast.error(text);
  playErrorSound();
};
const showSuccessSoundToast = (text = "") => {
  toast.success(text);
  playNotiSound();
};

export { showStatusSoundToast, showErrorSoundToast, showSuccessSoundToast };
