import React from "react";

import FullScreenBtn from "./FullScreenBtn";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";

const TopNav = (props) => {
  return (
    <nav className="top-nav">
      <div>
        <button onClick={props.toggleSidNav}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <div>
        <button>
          <FontAwesomeIcon icon={faUser} />
        </button>
        <FullScreenBtn />
      </div>
    </nav>
  );
};

export default TopNav;
