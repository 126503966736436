import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

// components
import MainLayout from "./layouts/MainLayout";
// import UserList from "./pages/users/UserList";
import Dashboard from "./pages/home/Dashboard";
import Login from "./pages/auth/Login";
import Admin from "./pages/users/Admin";
import Staff from "./pages/users/Staffs";
import Employer from "./pages/users/Employer";
import Employee from "./pages/users/Employees";
import WorkType from "./pages/work/WorkType";
import WorkSubType from "./pages/work/WorkSubType";

const MainApp = () => {
  const authenticated = useSelector((state) => state.auth.authenticated);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "users",
          // element: <UserList />,
          children: [
            {
              path: "admins",
              element: <Admin />,
            },
            {
              path: "staffs",
              element: <Staff />,
            },
            {
              path: "employees",
              element: <Employee />,
            },
            {
              path: "employers",
              element: <Employer />,
            },
          ],
        },
        {
          path: "work",
          // element: <UserList />,
          children: [
            {
              path: "types",
              element: <WorkType />,
            },
            {
              path: "sub-types",
              element: <WorkSubType />,
            },
          ],
        },
      ],
      loader: async () => (authenticated ? null : redirect("/login")),
    },
    {
      path: "login",
      element: <Login />,
      loader: async () => (authenticated ? redirect("/dashboard") : null),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default MainApp;
