import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";

const TablePagination = () => {
  return (
    <div className="tbl-pagination">
      <button>
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <button>1</button>
      <button>2</button>
      <button disabled>
        <FontAwesomeIcon icon={faEllipsis} />
      </button>
      <button className="active">3</button>
      <button disabled>
        <FontAwesomeIcon icon={faEllipsis} />
      </button>
      <button>4</button>
      <button>5</button>
      <button>
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};

export default TablePagination;
