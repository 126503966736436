const getAuthToken = () => {
  return sessionStorage.getItem("rozi_roti_auth_token");
};

const setAuthToken = (token) => {
  sessionStorage.setItem("rozi_roti_auth_token", token);
};

const loginUser = (token = null, user = {}) => {
  setAuthToken(token);
  sessionStorage.setItem("rozi_roti_user", JSON.stringify(user));
};

const loadUserInfo = () => {
  const user = sessionStorage.getItem("rozi_roti_user");
  return user ? JSON.parse(user) : {};
};

const checkInitialAuth = () => {
  return sessionStorage.getItem("rozi_roti_auth_token") ? true : false;
};

const logoutUser = () => {
  sessionStorage.clear();
};

export { getAuthToken, loginUser, logoutUser, loadUserInfo, checkInitialAuth };
