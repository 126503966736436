import axios from "axios";
import { getAuthToken } from "./auth";

// constants
const BASE_URL = "https://rozi-roti-apis.techkingdom.in";
// const BASE_URL = "http://localhost:8000";

const ERROR_MSG = { type: "error", text: "Something went wrong." };

// helpers functions
const genHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthToken()}`,
    "Content-Type": "application/json",
  };
};

// request functions

const getFromServer = async (url) => {
  try {
    const res = await axios.get(`${BASE_URL}${url}`, { headers: genHeaders() });
    if (res.status === 200 || res.status === 201) {
      return { status: true, data: res.data.data, detail: res.data.detail };
    } else {
      console.log("ERROR => Something went wrong.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.detail) {
      return { status: false, detail: error.response.data.detail };
    } else return { status: false, detail: ERROR_MSG };
  }
};

const postToServer = async (url, data = {}) => {
  try {
    const res = await axios.post(`${BASE_URL}${url}`, data, {
      headers: genHeaders(),
    });
    if (res.status === 200 || res.status === 201) {
      return { status: true, data: res.data.data, detail: res.data.detail };
    } else {
      console.log("ERROR => Something went wrong.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.detail) {
      return { status: false, detail: error.response.data.detail };
    } else return { status: false, detail: ERROR_MSG };
  }
};

export { getFromServer, postToServer };
