import React from "react";
import Toolbar from "../../widgets/tools/Toolbar";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const FormPopup = (props) => {
  return (
    <div className={`popup ${props.size}`}>
      <div className="overlay" onClick={() => console.log("Close Popup")}></div>
      <div className="content">
        <Toolbar actions={props.toolActions} title={props.title} />
        <div style={{ position: "relative" }}>
          {props.children}
          {props.loading && (
            <div className="f-loading">
              <div></div>
              <FontAwesomeIcon icon={faGear} spin />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormPopup;
