import React from "react";

const TableFilter = () => {
  return (
    <div className="tbl-filter">
      <select id="no_of_rows">
        <option value="10"># 10</option>
        <option value="20"># 20</option>
        <option value="50"># 50</option>
        <option value="100"># 100</option>
      </select>
      <div></div>
      <input type="search" placeholder="Search" />
    </div>
  );
};

export default TableFilter;
