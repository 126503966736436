import React, { useRef, useState } from "react";
import FormPopup from "../../widgets/popups/FormPopup";
import {
  showErrorSoundToast,
  showStatusSoundToast,
} from "../../../globals/messages";
import { postToServer } from "../../../globals/requests";

const TypeForm = (props) => {
  const name = useRef(null);
  const [loading, setLoading] = useState(false);
  const toolActions = {
    refresh: () => setLoading(!loading),
    close: () => props.close(),
    save: async () => {
      const data = {
        name: name.current.value.trim(),
      };
      if (data.name === "") {
        showErrorSoundToast("Please Enter Work Type");
        return;
      }
      const result = await postToServer("/admin/work-type/create", data);
      if (result.status) {
        props.close();
        props.refresh();
      }
      showStatusSoundToast(result.status, result.detail);

      console.log();
    },
  };

  return (
    <FormPopup
      close={props.close}
      toolActions={toolActions}
      loading={loading}
      title="Work Type Form"
      size="sm"
    >
      <div className="form">
        <div className="c1">
          <p>
            <label htmlFor="name">Work Type</label>
            <input type="text" required id="name" ref={name} />
          </p>
        </div>
      </div>
    </FormPopup>
  );
};

export default TypeForm;
